.landing-container {
  position: relative;
}

.landing-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 26%;
  margin-left: 9.5%;
  gap: 20px
}

.slogan {
  color: var(--gray-200);
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
  align-content: flex-start;
}

.home-button {
  font-size: var(--fs-lg);
  color: var(--gray-100);
  background-color: var(--primary);
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
}

.landing-img {
  width: 100%;
  z-index: 0;
}
