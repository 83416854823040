.cell {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1em;
  align-items: center;
}

.cell-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.cell-description {
  margin-bottom: 10px;
}
