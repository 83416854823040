.industrial {
  display: flex;
  background-color: #1b1819;
  color: white;
  justify-content: space-around;
  padding: 2em 0;
}

.horizontal-line {
  border: 1px solid #ee8a42;
  width: 150px;
  margin-left: 3px;
}

.industrial-info {
  display: flex;
  flex-direction: column;
  padding: 4em;
  text-align: left;
  justify-content: center;
}

.industrial-info p {
  width: 400px;
  margin-bottom: 2em;
}

.industrial-title {
  font-size: 36px;
  color: #ee8a42;
}

.industrial img {
  height: auto;
  width: 500px;
  padding: 6em;
}

.industrial-row {
  display: flex;
}
