.agro {
  display: flex;
}

.agroImage img {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.agroSummary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #1b1819;
  color: white;
}

.agroSummaryText {
  text-align: left;
  max-width: 300px;
}

.summarySubText {
  display: flex;
  margin-top: 3em;
  flex-direction: column;
}

.agroImage {
  display: flex;
}
