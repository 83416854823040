.product-button {
  display: flex;
  flex-direction: column;
  width: 140px;
  padding: 10px;
  margin: 0 20px 20px 0;
  cursor: pointer;
}

.product-button:hover {
  background-color: #ee8a42;
}

.product-button:hover .prod-button-number {
  color: white;
}

.prod-button-number {
  font-size: 28px;
  color: #ee8a42;
}

.prod-button-title {
  font-size: 22px;
}

.prod-button-description {
  margin-top: 10px;
  font-size: 16px;
}
