.projects {
  font-size: 14px;
}

.projects-title {
  display: flex;
  font-size: 36px;
  color: #ee8a42;
  text-align: left;
  max-width: 370px;
}

.projects-heading {
  padding: 4em 0 2em 9em;
}

.project-title {
  display: flex;
  font-size: 18px;
  margin-left: 5em;
}

.project-number {
  display: flex;
}

.project-name {
  display: flex;
  margin-left: 8em;
}

.project {
  display: flex;
  flex-direction: column;
  margin: 2em 16em;
  max-width: 500px;
}

.project-description {
  text-align: left;
}
