:root {
  /* -- Fonts -- */

  /* Size */
  --fs-3xs: 12px;
  --fs-2xs: 14px;
  --fs-xs: 16px;
  --fs-sm: 18px;
  --fs-md: 18px;
  --fs-lg: 20px;
  --fs-xl: 24px;
  --fs-2xl: 30px;
  --fs-3xl: 38px;

  /* Height */
  --lh-3xs: 18px;
  --lh-2xs: 21px;
  --lh-xs: 24px;
  --lh-sm: 28px;
  --lh-md: 22px;
  --lh-lg: 24px;
  --lh-xl: 30px;
  --lh-2xl: 36px;
  --lh-3xl: 44px;

  /* -- Colors -- */

  /* Hope pallet */
  --primary: #EF8A42;
  --primary-100: #F28500;

  --text-color: #153240;

  /* Gray */
  --gray-100: #f8f8f8;
  --gray-200: #efefef;
  --gray-300: #cdcdcd;
  --gray-400: #aaa;
  --gray-500: #7a7a7a;
  --gray-600: #4b4b4b;
  --gray-700: #3a3a3a;
  --gray-800: #1f1f1f;

  --white: #fff;

  --dm-secret-100: #fff4e7;
  --dm-secret-200: #ffedd8;
  --dm-secret-300: #ffc49c;
  --dm-secret-400: #d69062;
}
