.footer {
  display: flex;
  font-size: 14px;
  background-color: #1b1819;
  color: white;
  align-items: center;
  padding: 5em;
  justify-content: space-between;
}

.footer div {
  display: flex;
  flex-direction: column;
}

.footer-title {
  font-size: 18px;
}

.footer-left {
  text-align: left;
  max-width: 250px;
}

.footer-right {
  align-items: flex-end;
}

.footer-right span {
  display: flex;
  align-items: center;
  padding: 5px;
}

.footer-right span svg {
  margin-left: 10px;
}

.footer-right a {
  display: flex;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  color: var(--white);
}

.footer-right a svg {
  margin-left: 10px;
}
