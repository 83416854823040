.values {
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  margin: 4em;
}

.value {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 300px;
  padding: 20px;
  padding-top: 0;
}

.value > * {
  padding: 10px;
}

.value-number {
  color: #ee8a42;
  font-size: 18px;
}

.value-title {
  color: #ee8a42;
  font-size: 18px;
}

.value-description {

}

.values-items {
  margin: 4em;

}

.values-row {
  display: flex;
}

