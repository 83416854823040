.about-us {
  background-image: url('../../resources/aboutUs/aboutUsBackground.png');
  background-size: cover;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 9em 0;
}

.about-us-col {
  display: flex;
}

.about-us-col img {
  width: 100%;
  height: auto;
  max-width: 570px;
  margin-left: 60px;
}

.about-us-description {
  color: white;
  text-align: left;
  max-width: 400px;
}
