body {
  font-family: "Roboto", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0;
}

.home {
  background-image: url('../../resources/homeBackground.png');
  height: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
