.sanitary {
  display: flex;
  background-color: white;
  color: black;
  justify-content: space-around;
}

.sanitary-info {
  display: flex;
  flex-direction: column;
  padding: 6em;
  text-align: left;
  justify-content: center;
}

.sanitary-info p {
  width: 400px;
  margin-bottom: 2em;
}

.sanitary-title {
  font-size: 36px;
  color: #ee8a42;
}

.sanitary-row {
  display: flex;
}

.sanitary img {
  width: 500px;
  height: auto;
  padding: 2em;
}
