.projects-table {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 4em;
}

.projects-row {
  display: flex;
}
