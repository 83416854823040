.navigation {
  display: flex;
  align-items: center;
  height: 90px;
  position: absolute;
  width: 100%;
  padding: 3% 7%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
/*  background-color: rgba(0, 0, 0, 0.7);*/
}

.logo-img {
  width: 110%;
}

.navigation-menu {
  margin-left: auto;
  padding-right: 20px;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  margin: 0 1rem;
  padding: 4px 5px;
  border: 1.5px solid transparent;
  /*    background-color: var(--primary);*/
}

.navigation-menu li a {
  color: var(--gray-200);
  text-decoration: none;
  display: block;
  font-size: var(--fs-2xs);
  line-height: var(--lh-3xs);
  font-weight: 600;
  letter-spacing: 1.5px;
}

.navigation-menu:hover li:hover {
  color: var(--gray-700);
  cursor: pointer;
  border: 1.5px solid var(--primary);
  border-radius: 3px;
/*   background-color: var(--gray-400);*/
}

.navigation-menu:hover li:hover a {
  color: white;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #f6872d;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: var(--gray-300);
}

@media screen and (max-width: 590px) {
  .hamburger {
    display: block;
    z-index: 2;
  }

  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    border-top: 1px solid black;
    display: none;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
    background-color: var(--gray-100);
    border-bottom: 1px solid var(--primary);
  }

  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }

  .navigation-menu li:hover {
    background-color: var(--primary)
  }

  .navigation-menu.expanded ul {
    display: block;
  }
}
