.about-us-info {
  font-size: 14px;
}

.info-row {
  display: flex;
  border-bottom: 1px solid #EF8A42;
  align-items: center;
  justify-content: space-around;
  padding: 4em;
}

.info-row-big {
  font-size: 70px;
  text-align: left;
  max-width: 200px;
}

.info-row-small {
  font-size: 20px;
  text-align: left;
  max-width: 140px;
}
