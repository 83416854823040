.whatsapp-float {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-200);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 12px;
  color: #5ad167;
  height: 64px;
  width: 64px;
  transition: transform 0.2s ease;
  position: fixed;
  bottom: 25px;
  right: 4%;
  z-index: 0;
  cursor: pointer;
}

.whatsapp-float svg {
  height: 40px;
  width: 40px;
}

.whatsapp-float:hover {
  background-color: #5ad167;
  transform: scale(1.07);
}

.whatsapp-float:hover svg {
  fill: var(--white);
}

@media (max-width: 590px) {
  .whatsapp-float {
    width: 48px;
    height: 48px;
  }

  .whatsapp-float svg {
    height: 32px;
    width: 32px;
  }
}