.contact-container {
  background-image: url('../../resources/contact/contactBackground.png');
  background-size: cover;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 9rem 4em;
  color: #f9f9f9;
  letter-spacing: 1px;
}

.contact-col {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: black;
  justify-content: center;
  padding: 10px 5px;
}

.block-container {
  position: relative;
  max-width: 700px;
}

.contact-form {
  width: 100%;
  min-width: 300px;
}

.field-label {
  display: flex;
  margin-left: 10px;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.contact-input {
  width: 100%;
  font-size: 1rem;
  border: 1px solid #e1e1e1;
  padding: 10px 10px;
}

.contact-input::placeholder {
  font-family: 'Montserrat', sans-serif;
}

textarea {
  resize: none;
}

.contact-title {
  text-align: left;
  color: white;
  font-size: 50px;
  font-weight: normal;
  max-width: 600px;
}

button[type="submit"] {
	cursor:pointer;
	width: 40%;
	border:none;
	color:#f46100;
	padding:10px;
	font-size:15px;
}

.field-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 5px;
}

button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

button[type="submit"]:hover {
	background:#d8642a;
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}

@media only screen and (max-width: 1024px) {
  .contact-container {
    padding: 3rem 0;
    margin-left: 0rem;
    flex-direction: column;
  }

  .contact-form {
    max-width: 100%;
    margin-top: 2rem;
  }

  .separator {
    display: none;
  }
}
